export enum Partner {
  Aegean = 'aegean',
  AirFrance = 'airfrance',
  AirTransat = 'airtransat',
  Avianca = 'avianca',
  Easyjet = 'easyjet',
  FrenchBee = 'frenchbee',
  HawaiianAirlines = 'hawaiianairlines',
  Jazeera = 'jazeera',
  Jetstar = 'jetstar',
  LaCompagnie = 'lacompagnie',
  Norse = 'norse',
  Oneworld = 'oneworld',
  PhilippineAirlines = 'philippineairlines',
  Play = 'play',
  Skyteam = 'skyteam',
  TigerairTaiwan = 'tigerairtw',
  Transavia = 'transavia',
  VietJetAir = 'vietjetair',
  Volotea = 'volotea',
  Vueling = 'vueling',
  // Please make sure dohop is the last match here since this is the default partner.
  // We are mapping over this find the partner and since most of our domains include dohop we need to make sure we end with the dohop match
  // eslint-disable-next-line typescript-sort-keys/string-enum
  Dohop = 'dohop',
  DohopConnect = 'dohopconnect',
}

export enum KeyboardKey {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Backspace = 'Backspace',
  Enter = 'Enter',
  Escape = 'Escape',
  Tab = 'Tab',
}

export enum DisplayType {
  ExtraExtraLarge = 'ExtraExtraLarge',
  ExtraExtraSmall = 'ExtraExtraSmall',
  ExtraLarge = 'ExtraLarge',
  ExtraSmall = 'ExtraSmall',
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export enum Language {
  Chinese = 'zh',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  French = 'fr',
  German = 'de',
  Greek = 'el',
  Italian = 'it',
  Japanese = 'ja',
  Korean = 'ko',
  Norwegian = 'no',
  Portuguese = 'pt',
  Spanish = 'es',
  Thai = 'th',
}

export enum CurrencyCode {
  AED = 'AED',
  AFN = 'AFN',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  ARS = 'ARS',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BRL = 'BRL',
  BSD = 'BSD',
  BTN = 'BTN',
  BWP = 'BWP',
  BYN = 'BYN',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHF = 'CHF',
  CKD = 'CKD',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CVE = 'CVE',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  ERN = 'ERN',
  ETB = 'ETB',
  EUR = 'EUR',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MRU = 'MRU',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIO = 'NIO',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEB = 'PEB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STN = 'STN',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  USD = 'USD',
  UYU = 'UYU',
  UZS = 'UZS',
  VEF = 'VEF',
  VES = 'VES',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XCD = 'XCD',
  XOF = 'XOF',
  XPF = 'XPF',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
  ZWL = 'ZWL',
}

export enum ItineraryType {
  DIRECT = 'DIRECT',
  TWO_OR_MORE_STOPS = 'TWO_OR_MORE_STOPS',
  WITH_STOP = 'WITH_STOP',
}

export enum Sort {
  CHEAPEST = 'CHEAPEST',
  QUICKEST = 'QUICKEST',
  RECOMMENDED = 'RECOMMENDED',
}

export enum CMSPassengerType {
  ADULT = 'adult',
  CHILD = 'child',
  INFANT = 'infant',
}

export enum VendorType {
  AIR = 'air',
  RAIL = 'rail',
}

export enum PassengerFields {
  DATE_OF_BIRTH = 'date_of_birth',
  FIRST_NAME = 'first_name',
  FREQUENT_FLYER = 'frequent_flyer_numbers',
  GENDER = 'gender',
  LAST_NAME = 'last_name',
  PASSENGER_TYPE = 'passenger_type',
  PASSPORT_COUNTRY = 'passport_country',
  PASSPORT_EXPIRATION = 'passport_expiration',
  PASSPORT_NUMBER = 'passport_number',
  TITLE = 'title',
}

export enum ServiceClass {
  GROUND_TRANSPORT = 'ground_transport',
  LOUNGE_ACCESS = 'lounge_access',
  LUGGAGE_CABIN = 'luggage_cabin',
  LUGGAGE_CHECKED = 'luggage_checked',
  LUGGAGE_PERSONAL = 'luggage_personal',
  SEAT_SELECTION = 'seat_selection',
  SECURITY_FAST_TRACK = 'security_fast_track',
}

export enum RUNTIME_ENV {
  dev = 'dev',
  preprod = 'preprod',
  production = 'prod',
  staging = 'staging',
}

export enum TermsAndConditionsLinks {
  baggageAllowance = 'baggageAllowanceLinks',
  carriage = 'conditionsOfCarriageLinks',
  customerServicePlan = 'customerServicePlanLinks',
  dangerousGoods = 'dangerousGoodsLinks',
  fareRules = 'fareRulesLinks',
  none = 'none',
  partnerTrain = 'partnerTrainLinks',
  privacy = 'privacyPolicyLinks',
  tarmacDelay = 'tarmacDelayLinks',
  terms = 'termsLinks',
}

export const ApplicationStringNamespace = {
  ...Partner,
  Content: 'content',
  Shared: 'shared',
  TaxCodes: 'taxcodes',
} as const;
